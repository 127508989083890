.LinkTitleTop{
    // color: var(--bg-secondary);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #747474;
}


/* Menu icon circle mini */


.mini-circle{
    border-radius: 100%;
    // border: 2px solid var(--bg-secondary);
    // width: 33px;
    // height: 33px;
    // color: var(--bg-secondary);
    color: #555555;
    // color: #454545;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .7rem;
}