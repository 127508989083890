.LoaderLayoutMain1{
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.LoaderLayoutMain1__left{
    width: 220px;
    height: 100%;
    position: absolute;
    background-color: #f6f6f6;
}

.LoaderLayoutMain1__right{
    width: calc(100% - 220px);
    min-height: 100vh;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 14%);
    z-index: 10;
    position: relative;
    float: right;
}