.LayoutMain{
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.LayoutMain__navleft--container{
    transition: .3s ease all;

}

.LayoutMain__navleft{
    width: 260px;
    /* border-right: 1px solid #ddd; */
    height: 100%;
    position: fixed;
    background-color: #F6F6F6;
    left: -100%;
    transition: .3s ease all;
    z-index: 20;
}

.LayoutMain__navleft--active .LayoutMain__navleft{
    left: 0;
}

#LayoutMain__navleft--backdrop{
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: rgb(0 0 0 / 46%);
    transition: .3s ease all;
    display: none;
    z-index: 13;
}

.LayoutMain__navleft--active #LayoutMain__navleft--backdrop{
    opacity: 1;
    display: block;
}

@media (min-width: 768px){
    .LayoutMain__navleft{
        width: 220px;
        height: 100%;
        left: 0;
        z-index: 10;

    }
}

.LayoutMain__navleft img{
    margin: 0 auto;
    padding-top: 1.2rem;
    display: block;
}

.LayoutMain__navmenu{
    position: relative;
    bottom: 0;
    padding: 1rem 1.4rem;
    border-top: 1px solid #ddd;
}

.LayoutMain__contentright{
    width: 100%;
    float: right;
    background-color: #fff;
    z-index: 10;
    min-height: 100vh;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 14%);
    position: relative;
    transition: .3s ease all;

}

@media (min-width: 768px){
    .LayoutMain__contentright{
        width: calc(100% - 220px);
        z-index: 111;
    }
}

.LayoutMain__navtop{
    /* position: sticky; */
    top: 0;
    background-color: #fff;
    background-color: #fafafa;
    /* height: 100px; */
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: .3rem;
    padding-right: .3rem;
    z-index: 5;
    height: 54px;
    height: 50px;
}

@media (min-width: 768px){
    .LayoutMain__navtop{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    /* .LayoutMain__navtop:after{
        position: absolute;
        content: '';
        width: 5px;
        height: 50px;
        left: -5px;
        background-color: red;
    } */
}

.LayoutMain__contentmain{
    padding-top: 1.3rem;
}

@media (min-width: 768px){
    .LayoutMain__contentmain{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.LayoutMain__navlinks{
    margin-top: 1rem;
}

.LayoutMain__navlinks a{
    padding: 0.6rem 2rem;
    text-decoration: none;
    /* color: hsla(0,0%,100%,.6); */
    color: #333;
    margin: .8rem 0;
    transition: ease all .3s;
}

@media (min-width: 768px){
    .LayoutMain__navlinks a{
        padding: 0.6rem 1.4rem;
    }
}


.LayoutMain__navlinks a:hover,
.LayoutMain__navlinks a.active{
    color: var(--bg-primary-2);
}


.LayoutMain__navmenu:hover {
    color: var(--bg-primary-2);
    cursor: pointer;
}

#btn_show_filter_advance{
    opacity: .8;
    transition: .3s ease all;
}

#btn_show_filter_advance:hover{
    cursor: pointer;
    opacity: 1;
}

/* Responsive */
