.read-more-container {
    position: relative;
  }
  
  .text {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .text.collapsed {
    white-space: normal;
  }
  
  .text.expanded {
    white-space: normal;
  }
  
  .read-more-button {
    /* background-color: transparent;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    text-decoration: underline; */
    position: relative;
  }
  .read-more-button::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 25px;
    left: 0;
    top: -25px;
    background-image: linear-gradient(transparent, white);
  }
  
  /* .read-more-button:hover {
    color: darkblue;
  } */

  .TicketMensaje__descripcion{
    position: relative;
  }

  .TicketMensaje__descripcion .btnHref{
    position: absolute;
    /* display: none; */
    /* transform: translate(-50%, -50%); */
    z-index: 100;
  }

  .TicketMensaje__descripcion img{
    max-width: 100%;
    max-height: min-content;
  }