.OverlayLoader{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #0000004f;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OverlayLoader .spinner-border{
    width: 3rem;
    height: 3rem;
    border: 0.3rem solid currentcolor;
    border-right-color: transparent;
}