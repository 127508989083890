// Mixin para breakpoints 

$breakpoints: (
    'sm': 576px,
    'md': 768px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1400px
);

@mixin min-sm{
    @media (min-width: map-get($breakpoints, 'sm')) {
        @content;
    }
}

@mixin min-md{
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}

@mixin min-lg{
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}

@mixin min-xl{
    @media (min-width: map-get($breakpoints, 'xl')) {
        @content;
    }
}

@mixin min-xxl{
    @media (min-width: map-get($breakpoints, 'xxl')) {
        @content;
    }
}

@mixin min-breakpoint($breakpoint){
    @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
    }
}