.ListFiles li{
    cursor: auto !important;
}

.ListFilesInline{
    flex-direction: row !important;
}

.ListFilesInline li{
    border: 1px solid var(--bs-list-group-border-color) !important;
    border-radius: 5px;
    margin: 5px !important;
}

.ListFiles--small{
    /* font-size: .85rem; */
}

.ListFiles--small .list-group-item{
    padding: .5rem .7rem;
}

