@import './mixins.scss';

.btn-transparent{
    color: var(--text-secondary);
    background-color: transparent;
    border: 0;
    line-height: 0;
  }

  
.fw-500 {
    font-weight: 500;
  }
  
.fs-105{
  font-size: 1.05rem;
}

.fs-115{
  font-size: 1.15rem;
}

.fs-13{
  font-size: 1.3rem;
}

.me-25{
  margin-right: .75rem !important;
}


.disabledCursor { 
  pointer-events: none;
}

.pointer,
.cursor-pointer{
  cursor: pointer;
}

.color-secondary{
  color: var(--bg-secondary);
}

.color-primary{
  color: var(--bg-primary);
}

.min-w-th-3{
  min-width: 250px;
}

.min-w-20rem{
  min-width: 20rem;
}

.max-w-20rem{
  max-width: 20rem;
}


.max-w-15rem{
  max-width: 15rem;
}

.max-w-6rem{
  max-width: 6rem;
}

.min-w-6rem{
  min-width: 6rem;
}

.flex-1{
  flex: 1;
}

.table-shrink-th thead th:last-child{
  width: 0.1%;
  white-space: nowrap;
}

.shrink-td {
  width: 0.1%;
  white-space: nowrap;
}

.AllActionsPerLineAlignEnd .AllActionsPerLine{
  text-align: right !important;
}

.AllActionsPerLine > div:first-child{
  margin-left: 0 !important;
}

.text-nowrap{
  white-space: nowrap;
}

.table-orderby-active{
  color: var(--bg-secondary);
  font-weight: 900;
}

a.link-gray{
    color: #333;
}

a.link-gray:hover{
    color: var(--bs-link-color);
}

.bg-FAFAFA{
  background-color: #FAFAFA;
}

.bg-gray-1{
  background-color: #f8f8f8 !important;
}

.tr-red{
  background-color: #ffebeb !important;
}

.tr-td-transparent td{
  --bs-table-accent-bg: transparent !important;
}

.tr-form-check-none .form-check{
  display: none;
}

.fade.modal-backdrop.show {
  z-index: 1060;
}

.modal{
  z-index: 1060;
}

.hover-text-decoration:hover{
  text-decoration: underline !important;
}

.color-danger{
  color: #842029;
}

.card-danger{
  border: 1px solid #ffd2d6;
  background-color: #fffafb;
}

.modal-70vw{
  width: 70vw;    /* Occupy the 90% of the screen width */
  max-width: 70vw;
} 

@include min-xxl{
  .pb-lg-8{
    padding-bottom: 8rem !important;
  }

  .mb-xxl-0{
    margin-bottom: 0rem !important;
  }
}

// Overwrite

.BagdeAdvanced--outline{
  background: transparent !important;
  color: #555;
  border: 1px solid #bdbdbd !important;
}

.bg-transparent{
  background-color: transparent;
}

.TicketMensaje__Item{
  position: relative;
  // overflow: hidden;
  z-index: 10;
}

.TicketMensaje__Item::after{
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.175);
  left: 2rem;
  top: -1.56rem;
}

.TicketMensaje__Item.disableLine::after{
  display: none;
}

@media (min-width: 1400px) {
  .col-xxl-shrink{
      -webkit-flex: 0 0 auto;
      -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      // width: 16.66666667%;
  }
}

.TicketMensaje__ItemObservaciones{
  border: 1px solid #ddd;
  padding: .5rem;
  font-size: .9rem;
  border-radius: 3px;
}

.tox-dialog__body .tox-dialog__body-nav + .tox-dialog__body-content .tox-form .tox-form__group:nth-child(1) button{
  display: none !important;
}

.color-red-1{
  color: #b23232;
}

.bg-light-red{
  background-color: #fff4f4 !important;
}