
.Placeholder{
    display: block;
    margin: auto;
    min-width: 100px;
    opacity: .5;
    animation: fading 1s infinite;
    /* animation-timing-function: steps(10,end); */
}

.Placeholder__content{
  background-color: #ededed !important;
}

.delay-1{
  animation-delay: .2s;
}

.delay-2{
  animation-delay: .4s;
}

.delay-3{
  animation-delay: .6s;
}

.delay-4{
  animation-delay: .8s;
}

.delay-5{
  animation-delay: 1s;
}

@keyframes fading {
    0% {
      opacity: .5;
      /* transform: scale(0,0); */
      transform-origin: 0;
    }
    
    50% {
      opacity: 1;
    }
    
    100% {
      opacity: .5;
      /* transform: scale(1,1); */
      transform-origin: 0;
    }
  }