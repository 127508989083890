@import 'src/assets/css/mixins.scss';

.ButtonModule {
  cursor: pointer;
  width: 160px;
  margin-bottom: 1.7rem;
  transition: .3s ease all;
}

@media (min-width: 576px) {

  .ButtonModule {
    width: 200px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.ButtonModule:hover {
  /* background-color: #ececec; */
}

.ButtonModule:hover .ButtonModule__icon {
  background-color: var(--bg-primary);
}

.ButtonModule__Admin.ButtonModule:hover .ButtonModule__icon {
  background-color: var(--bg-secondary);
}

.ButtonModule:hover .ButtonModule__icon svg {
  color: #fff;
}

.ButtonModule:hover .ButtonModule__title {
  color: #000;
}

.ButtonModule__icon {
  width: 65px;
  height: 65px;
  margin: 0 auto;

  border-radius: 100%;
  border: 2px solid var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: .8rem;
  transition: .3s ease all;
}

@media (min-width: 576px) {
  .ButtonModule__icon {
    width: 80px;
    height: 80px;
  }
}

.ButtonModule__Admin .ButtonModule__icon{
  border: 2px solid var(--bg-secondary);
}

.ButtonModule__icon svg {
  font-size: 1.5rem;
  position: absolute;
  color: var(--bg-primary);
  transition: .3s ease all;

  @include min-sm{
    font-size: 2rem;
  }
}

.ButtonModule__Admin .ButtonModule__icon svg{
  color: var(--bg-secondary);
}


.Apps__logo{

  width: 120px;

  @include min-sm{
    width: 150px;
  }
}

.ButtonModule__title {
  font-size: 1.15rem;
  color: #7B7B7B;
  font-weight: normal;
  transition: .3s ease all;
}


#Apps_links .container-fluid{
  max-width: 1300px;
}

@media (min-width: 576px) {
  #Apps_links {
    // padding-bottom: 8rem;
  }
}

/* Animaciones */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpModules {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

// Disabled buttons temp

.ButtonModule--disabled,
.ButtonModule--disabled *{
  pointer-events: none;
  color: rgb(194, 194, 194) !important;
  // opacity: .5;

  border-color: rgb(194, 194, 194) !important;
}

.PageApp__LoaderIcon{
    border-radius: 50%!important;
}