.InputImage__input{
    padding: 15px !important;
    flex: 1;
    margin: 0 !important;
}

.InputImage__container{
    padding: .7rem !important;
    flex: 1;
    background-color: #ffffff !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
}
.InputImage .box-input-file-elem {
    width: 300px;
    height: 400px;
    display: inline-flex;
    margin: 15px;
    align-items: center;
    padding: 0.7rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.box-input-file-elem img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.elem-option-1 {
    top: 1.5rem;
    right: -2.8rem;
}

.box-input-file-elem:hover .elem-option-1 {
    right: 1.5rem;
}

.box-input-file-elem-option {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
    background-color: #fff;
    position: absolute;
    z-index: 20;
    align-items: center;
    transition: all .3s ease;
    justify-content: center;
    display: flex;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
}

.box-input-file-elem:hover:after {
    content: "";
    background-color: rgb(0 0 0/20%);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 11;
}

.box-input-file-elem:after {
    content: "";
    transition: all .3s ease;
}