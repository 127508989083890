.BreadcrumbLine a{
    text-decoration: none;
}

.BreadcrumbLine a:hover{
    color: var(--bg-primary--hover);
}

.breadcrumb-item.active{
    color: #333;
}

.BreadcrumbLine--disable_mb ol {
    margin-bottom: 0;
}

.BreadcrumbLine .breadcrumb{
    margin-bottom: 0;
}