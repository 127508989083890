:root {
    --bg-primary: #0E065E;
    --bg-primary-rgb: 14, 6, 94;

    --bg-primary--hover: #0e0845;
    --bg-primary--hover-rgba: 14, 8, 69;

    --bg-primary-2: #0E065E;
    --bg-primary-rgb-2: 14, 6, 94;

    --bg-secondary: #FF8B00;
    --bg-secondary-rgba: 255, 139, 0;

    --bg-secondary-hover: #e57d16;
    --bg-secondary-hover-rgba: 229, 125, 22;

    --bs-btn-bg: #0E065E;
    --bs-link-color: #0E065E;

    --light-20: rgba(225,231,240,0.2);
}
