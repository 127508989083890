.LoaderLayoutMain1{
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.LoaderLayoutMain1__left{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #f6f6f6;
}

@media (min-width: 576px) {
    .LoaderLayoutMain1__left{
        width: 220px;
    }
}

.LoaderLayoutMain1__right{
    display: none;
    width: 100%;
    min-height: 100vh;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 14%);
    z-index: 10;
    position: relative;
    float: right;
}

@media (min-width: 576px) {
    .LoaderLayoutMain1__right{
        display: block;
        width: calc(100% - 220px);
    }
}
