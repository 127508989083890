
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--color-dark);
    outline: 0;
    box-shadow: none;
}
  
.form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--color-dark);
    outline: 0;
    box-shadow: none;
}

.table {
    --bs-table-striped-bg: rgba(0, 0, 0, 0.02);
}

.form-check-input:focus{
    box-shadow: none;
    border-color: var(--color-dark);
}

.form-check-input:checked {
    background-color: var(--bg-secondary);
    border-color: var(--bg-secondary);
}

.table tbody tr td:last-child button:first-child{
    margin-left: 0 !important;
}

// Buttons

.btn{
    border-radius: 0.25rem;
}

.btn:focus{
    box-shadow: none !important;
}

.btn-primary{
    --bs-btn-bg: #0E065E !important;
    background-color: var(--bs-btn-bg);
    border-color: var(--bg-primary)!important;
    box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:focus{
    background-color: var(--bg-primary--hover) !important;
    outline: 0 !important;
}
  
.btn-outline-primary{
    border-color: var(--bg-primary--hover)!important;
    color: var(--bg-primary--hover)!important;
    background-color: #fff !important;
}

.btn-outline-primary:hover{
    background-color: rgba(var(--bg-primary--hover-rgba), 0.05)!important;
}

.btn-secondary{
    
    background-color: var(--bg-secondary);
    box-shadow: none !important;
    border-color: var(--bg-secondary)!important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active{
    background-color: var(--bg-secondary-hover) !important;
    outline: 0 !important;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    pointer-events: none;
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
}

.btn-secondary:disabled{
    background-color: rgb(var(--bg-secondary-rgba), 0.8) !important;
}

.btn-outline-secondary{
    border-color: var(--bg-secondary-hover)!important;
    color: var(--bg-secondary-hover)!important;
    background-color: #fff !important;
}

.btn-outline-secondary:hover{
    background-color: rgba(var(--bg-secondary-hover-rgba), 0.05)!important;
}



// Dropdowns

.dropdown-item.active, .dropdown-item:active{
    background-color: rgba(var(--bg-primary--hover-rgba))!important;
    // color: var(--bs-dropdown-link-color);
}

// tables

.minByDropdown .table-responsive{
    min-height: 215px;
}

// Accordion

.accordion-button:not(.collapsed){
    color: #333 !important;
    background-color: rgba(var(--bg-secondary-hover-rgba), 0.05)!important;

}

.accordion-button:focus{
    // border: 0 !important;
    box-shadow: none !important;
}

.Container__AccordionHeaderStatic > button{
    position: absolute;
    top: .9rem;
    right: 1rem;
    z-index: 20;
}

.Container__AccordionHeaderStatic .accordion-button::after{
    margin-left: 0;
}

.alert-secondary{
    background-color: #f4f4f4 !important;
}