.PageLogin{
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.PageLogin__Logo{
    display: block;
    width: 160px;
    margin: 0 auto;
    margin-top: 3rem;
}

.PageLogin .tab-content{
    background-color: #fff;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 1.4rem 1rem;
}

.PageLogin__title{
    margin-bottom: 1.7rem;
}

.PageLogin .nav-item{
    flex: 1;
}

.PageLogin .nav-item button{
    width: 100%;
    padding-top: .6rem;
    padding-bottom: .6rem;
}